import * as Yup from 'yup';

export const shortTextValidationSchema = Yup.object({
  field_label: Yup.string().required('Required field'),
  min: Yup.number()
    .min(0, 'Min must be larger then 0')
    .max(50, 'Min must be less or equal to 50')
    .nullable()
    .typeError('Min must be a number'),
  max: Yup.number()
    .min(Yup.ref('min'), 'Max must be greater than or equal to Min')
    .nullable()
    .max(50, 'Max must be less or equal to 50'),
});

export const longTextValidationSchema = Yup.object({
  field_label: Yup.string().required('Required field'),
  min: Yup.number().min(0, 'Min must be larger then 0').nullable(),
  max: Yup.number()
    .nullable()
    .min(Yup.ref('min'), 'Max must be greater than or equal to Min')
    .min(50, 'Max must be greater than 50')
    .max(500, 'Max must be less or equal to 500')
    .typeError('Max must be a number'),
});

export const NumberValidationSchema = Yup.object({
  field_label: Yup.string().required('Required field'),
  min: Yup.number()
    .nullable()
    .min(0, 'Min must be larger then 0')
    .typeError('Min must be a number'),
  max: Yup.number()
    .min(Yup.ref('min'), 'Max must be greater than or equal to Min')
    .nullable(),
});

export const RadioValidationSchema = Yup.object({
  field_label: Yup.string().required('Required field'),
  options: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Option name is required'),
      price: Yup.object().shape({
        price_type: Yup.string().oneOf(['fixed', 'percentage']).required(),
        amount: Yup.number()
          .nullable()
          .when('price_type', {
            is: 'percentage',
            then: (schema) =>
              schema
                .min(-100, 'Price cannot be less than -100%')
                .typeError('Amount must be a number'),
            otherwise: (schema) => schema.nullable(),
          }),
      }),
    })
  ),
});

export const ListValidationSchema = Yup.object({
  field_label: Yup.string().required('Required field'),
  options: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Option name is required'),
      price: Yup.object().shape({
        price_type: Yup.string().oneOf(['fixed', 'percentage']).required(),
        amount: Yup.number()
          .nullable()
          .when('price_type', {
            is: 'percentage',
            then: (schema) =>
              schema
                .min(-100, 'Price cannot be less than -100%')
                .typeError('Amount must be a number'),
            otherwise: (schema) => schema.nullable(),
          }),
      }),
    })
  ),
});

export const ToggleValidationSchema = Yup.object({
  field_label: Yup.string().required('Required field'),
  price: Yup.object({
    price_type: Yup.string().oneOf(['fixed', 'percentage']).required(),
    amount: Yup.number()
      .when('price_type', {
        is: 'percentage',
        then: (schema) => schema.min(-100, 'Price cannot be less than -100%'),
        otherwise: (schema) => schema.nullable(),
      })
      .typeError('Amount must be a number'),
  }),
});

export function validateDefaultField(
  min: number | null,
  max: number | null,
  defaultString: string
) {
  if (defaultString?.length > 0) {
    if (max !== null && defaultString.length > max) {
      return `Default text cannot exceed ${max}`;
    }
    if (min !== null && defaultString.length < min) {
      return `Default text must be at least ${min}`;
    }
  }
  return undefined;
}

export function validateDefaultFieldNumber(
  min: number | null,
  max: number | null,
  defaultValue: number
) {
  if (defaultValue !== null && defaultValue > 0) {
    if (max !== null && defaultValue > max) {
      return `Default value cannot exceed ${max}`;
    }
    if (min !== null && defaultValue < min) {
      return `Default value must be at least ${min}`;
    }
  }
  return undefined;
}
