import { AnyAction } from '@reduxjs/toolkit';
import {
  SET_EDIT_CONFIGURATION_VALIDATION_WARNINGS,
  RESET_EDIT_CONFIGURATION_VALIDATION_WARNINGS,
  SET_IS_DARK_MODE,
  SET_APP_MODE,
  SET_QUERY_PARAMS_PROCESSED,
  SET_IS_MOBILE_APP,
  SET_IS_LOADING_SCREEN_SHOWN,
} from '../../constants';
import { IQuestionDifferences } from 'providers/QueryProvider/validation';

export enum AppMode {
  BLUEPRINT = 'BLUEPRINT',
  SUPPLIER = 'SUPPLIER',
  COMPANY = 'COMPANY',
  DEALER_PANEL = 'DEALER_PANEL',
}

export interface ICommonReducerState {
  editConfigurationValidationWarnings: IQuestionDifferences;
  darkMode: boolean;
  queryParamsProcessed: boolean;
  appMode: AppMode;
  isMobileApp: boolean;
  isLoadingScreenShown: boolean;
}

const INIT_STATE: ICommonReducerState = {
  editConfigurationValidationWarnings: {
    missingQuestionsKeys: [],
    extraQuestionsKeys: [],
    matchingQuestionsWithInvalidOutputsKeys: [],
  },
  queryParamsProcessed: false,
  darkMode: false,
  appMode: AppMode.SUPPLIER,
  isMobileApp: false,
  isLoadingScreenShown: false,
};

const commonReducer = (state = INIT_STATE, action: AnyAction) => {
  switch (action.type) {
    case SET_EDIT_CONFIGURATION_VALIDATION_WARNINGS:
      return {
        ...state,
        editConfigurationValidationWarnings: action.payload,
      };
    case RESET_EDIT_CONFIGURATION_VALIDATION_WARNINGS:
      return {
        ...state,
        editConfigurationValidationWarnings:
          INIT_STATE.editConfigurationValidationWarnings,
      };
    case SET_IS_DARK_MODE:
      return {
        ...state,
        darkMode: action.payload,
      };
    case SET_APP_MODE:
      return {
        ...state,
        appMode: action.payload,
      };
    case SET_QUERY_PARAMS_PROCESSED:
      return {
        ...state,
        queryParamsProcessed: action.payload,
      };
    case SET_IS_MOBILE_APP:
      return {
        ...state,
        isMobileApp: action.payload,
      };
    case SET_IS_LOADING_SCREEN_SHOWN:
      return {
        ...state,
        isLoadingScreenShown: action.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;
