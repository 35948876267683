import { Skeleton } from '@mui/material';
import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

export const MODEL_ROW_CONTAINER_HEIGHT = 65;

interface StyledProps {
  withoutLine: boolean;
  opacityIndex: number;
  opacityCoeficient: number;
  locked: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 50rem;
  align-items: center;
  height: ${MODEL_ROW_CONTAINER_HEIGHT}rem;
  ${(props: StyledProps) =>
    !props.withoutLine &&
    css`
      border-bottom: 1rem solid ${COLORS.BORDER};
    `}
  ${(props: StyledProps) =>
    props.opacityIndex !== undefined &&
    css`
      opacity: ${1 - (props.opacityIndex * props.opacityCoeficient + 0.2)};
    `}

  ${respondTo.smallTablet`
    height: 50rem;
  `}
`;

export const LeftPart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5rem;
  flex: 1;
  justify-content: space-between;

  ${respondTo.bigTablet`
    margin-left: 0;
  `}
`;

export const ModelNameLabel = styled.label`
  font-size: 17rem;
  font-weight: 400;
  color: ${COLORS.GREEN};

  ${respondTo.smallTablet`
    font-size: 16rem;
  `}
`;

export const RightPart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 60rem;
  flex: 1;
`;

export const CircleSkeleton = styled(Skeleton)`
  border-radius: 13rem;
`;

export const StyledSkeleton = styled(Skeleton)`
  border-radius: 15rem;
`;

export const Version = styled.div`
  display: flex;
  align-items: center;
  gap: 5rem;
`;

export const VersionText = styled.span`
  display: block;
  font-size: 17rem;
  color: ${COLORS.BLACK};
`;

export const VersionNumber = styled.span`
  display: block;
  font-size: 17rem;
  color: ${COLORS.GREEN};
  width: 25rem;
`;

export const Status = styled.span`
  display: block;
  color: ${COLORS.GREEN};
  font-size: 17rem;
  min-width: 80rem;
`;

export const MidPart = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  flex: 1.5;
`;

export const DateText = styled.span`
  display: block;
  font-size: 17rem;
  color: ${COLORS.BLACK};
`;

export const Date = styled(DateText)``;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 50rem;
`;

export const ActionButton = styled.div`
  display: flex;
  align-items: center;
  gap: 5rem;
  cursor: pointer;
  width: 80rem;
  ${(props: StyledProps) =>
    props.locked &&
    css`
      cursor: not-allowed;
    `}
`;

export const ActionText = styled.span`
  display: block;
  font-size: 17rem;
  color: ${COLORS.GREEN};

  ${(props: StyledProps) =>
    props.locked &&
    css`
      color: ${COLORS.BORDER};
      cursor: not-allowed;
    `}
`;

export const Options = styled.div`
  display: flex;
  align-items: center;
  gap: 30rem;
`;

export const StateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20rem;
`;

export const IconWrapper = styled.div`
  width: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StateText = styled.span`
  font-size: 17rem;
  color: ${COLORS.GREEN};
  display: block;
  width: 65rem;
`;

export const Label = styled.label`
  font-size: 17rem;
  font-weight: 400;
  color: ${COLORS.BLACK};

  ${respondTo.smallTablet`
    font-size: 16rem;
  `}
`;
