import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import modelReducer, { IModelReducerState } from './Model/reducers/model';
import tableModalReducer, {
  ITableModalReducerState,
} from './TableModal/reducers/tableModal';
import commonReducer, { ICommonReducerState } from './Common/reducers/common';
import shoppingCartReducer, {
  IShoppingCartReducerState,
} from './ShoppingCart/reducers/shoppingCart';
import submitConfigurationReducer, {
  ISubmitConfigurationReducerState,
} from './SubmitConfiguration/reducers/submitConfiguration';
import createTransform from 'redux-persist/es/createTransform';
import formReducer, { IFormReducerState } from './Form/reducers/form';
import userReducer, { IUserReducerState } from './User/reducers/user';
import filtersReducer, {
  IFiltersReducerState,
} from './Filters/reducers/filters';
import colorModalReducer, {
  IColorModalReducerState,
} from './ColorModal/reducers/colorModal';

const storeTansform = createTransform(
  // Transform the incoming state, persisting only the desired part
  (
    inboundState:
      | IModelReducerState
      | ICommonReducerState
      | IFormReducerState
      | IShoppingCartReducerState
      | ISubmitConfigurationReducerState
      | IUserReducerState
      | IFiltersReducerState
      | ITableModalReducerState
      | IColorModalReducerState
      | any,
    key
  ) => {
    if (key === 'modelInfo') {
      return {
        ...inboundState,
        models: null,
        questions: [],
        variables: null,
        images: null,
        pricing: null,
        roundPricing: false,
        queryStatus: {
          attempt: false,
          success: false,
          error: null,
        },
        lang: 'en',
      };
    } else if (key === 'commonInfo') {
      return {
        ...inboundState,
        isLoadingScreenShown: false,
        queryParamsProcessed: false,
        editConfigurationValidationWarnings: [],
      };
    } else if (key === 'formInfo') {
      return {
        ...inboundState,
        isFormValid: inboundState.isFormValid
          ? inboundState.isFormValid
          : false,
        areAllFieldsTouched: false,
        touchedQuestionNames: [],
        modelNotFound: false,
        questionPrices: [],
      };
    } else if (key === 'shoppingCartInfo') {
      return {
        ...inboundState,
        isShoppingCartMode: true,
        triggerAddToShoppingCart: false,
        triggerShowAddedToCartModal: false,
        currentlyEditingShoppingCartLineId: null,
        triggerShowCartItemEditedModal: false,
        triggerEditCartItem: false,
      };
    } else if (key === 'submitConfigurationInfo') {
      return {
        ...inboundState,
        intentUUID: null,
        triggerSubmitConfiguration: false,
        getEditConfigurationStatus: {
          attempt: false,
          success: false,
          error: false,
        },
      };
    } else if (key === 'userInfo') {
      return {
        ...inboundState,
      };
    } else if (key === 'colorModalInfo') {
      return {
        isOpen: true,
        question: null,
      };
    }
    return inboundState;
  },
  // No need for an outbound transform
  (outboundState: any, key) => {
    return outboundState;
  }
);

const persistConfig = {
  key: 'root',
  storage,
  transforms: [storeTansform],
};

const rootReducer = combineReducers({
  modelInfo: modelReducer,
  tableModalInfo: tableModalReducer,
  colorModalInfo: colorModalReducer,
  commonInfo: commonReducer,
  shoppingCartInfo: shoppingCartReducer,
  submitConfigurationInfo: submitConfigurationReducer,
  formInfo: formReducer,
  userInfo: userReducer,
  filtersInfo: filtersReducer,
});
export type IRootReducerState = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
