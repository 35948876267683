import { IConfiguredProductData } from 'pages/ConfigurePage/ConfigureForm/hooks';
import i18n, { FALLBACK_LOCALE } from 'providers/i18n/i18n';
import { queryModel } from 'services/ModelService';
import { parseQuestionsObjectResponseIntoArray } from 'store/Model/reducers/model';
import { IQuestion, QuestionType } from 'types/Question.types';

export interface IQuestionDifferences {
  missingQuestionsKeys: string[];
  extraQuestionsKeys: string[];
  matchingQuestionsWithInvalidOutputsKeys: string[];
}

export const checkQuestionDifferences = async (
  editConfigurationData: IConfiguredProductData
) => {
  if (!editConfigurationData || !editConfigurationData.modelId) {
    return {
      missingQuestionsKeys: [],
      extraQuestionsKeys: [],
      matchingQuestionsWithInvalidOutputsKeys: [],
    };
  }

  // Query with the same language as initial configuration to avoid raising validation errors
  const lang = editConfigurationData.lang || FALLBACK_LOCALE;
  i18n.changeLanguage(lang);
  const queryModelPromise = queryModel(
    editConfigurationData.modelId.toString(),
    { questions: editConfigurationData.queryPayload } || {},
    lang
  );
  let queryData: any = {};
  try {
    [queryData] = await Promise.all([queryModelPromise]);
  } catch (error: any) {
    return checkQuestionsMismatchWhenQueryError(
      error?.response?.data?.errors || []
    );
  }
  return checkQuestionsMismatch(
    editConfigurationData.questions,
    parseQuestionsObjectResponseIntoArray(queryData.questions)
  );
};

interface IQueryQuestionKeyError {
  id: string;
  message: string;
  position: string;
}
const checkQuestionsMismatchWhenQueryError = (
  errors: IQueryQuestionKeyError[]
): IQuestionDifferences => {
  /* Might require handling of additional backend error types */
  const extractQuestionKeyFromErrorMsg = (text: string) => {
    // Extract text between quotes after "question key"
    const regex = /question key "([^"]+)"/;
    const match = text.match(regex);
    return match ? match[1] : '';
  };
  return {
    missingQuestionsKeys: [],
    extraQuestionsKeys: [],
    matchingQuestionsWithInvalidOutputsKeys: errors.map(
      (error: IQueryQuestionKeyError) =>
        extractQuestionKeyFromErrorMsg(error.position)
    ),
  };
};

const checkQuestionsMismatch = (
  editQuestions: IQuestion[],
  currentQuestions: IQuestion[]
): IQuestionDifferences => {
  const missingQuestions: IQuestion[] = [];
  const extraQuestions: IQuestion[] = [];
  const matchingQuestionsWithInvalidOutputs: IQuestion[] = [];

  // Find missing questions
  for (const editQuestion of editQuestions) {
    let currentQuestionThatMatchesEditQuestion: IQuestion | null = null;
    for (const currentQuestion of currentQuestions) {
      if (currentQuestion.initialKey === editQuestion.initialKey) {
        currentQuestionThatMatchesEditQuestion = currentQuestion;
      }
    }
    if (!currentQuestionThatMatchesEditQuestion) {
      missingQuestions.push(editQuestion);
    } else {
      // This question exists in both editQuestions and currentQuestions,
      // check if edit question output exists as a valid input
      if (
        !isEditQuestionOutputValid(
          editQuestion,
          currentQuestionThatMatchesEditQuestion
        )
      ) {
        matchingQuestionsWithInvalidOutputs.push(
          currentQuestionThatMatchesEditQuestion
        );
      }
    }
  }

  // Find extra questions
  for (const currentQuestion of currentQuestions) {
    let currentQuestionFound = false;
    for (const editQuestion of editQuestions) {
      if (currentQuestion.initialKey === editQuestion.initialKey) {
        currentQuestionFound = true;
      }
    }
    if (!currentQuestionFound) {
      extraQuestions.push(currentQuestion);
    }
  }

  return {
    missingQuestionsKeys: missingQuestions.map(
      (question: IQuestion) => question.initialKey
    ),
    extraQuestionsKeys: extraQuestions.map(
      (question: IQuestion) => question.initialKey
    ),
    matchingQuestionsWithInvalidOutputsKeys:
      matchingQuestionsWithInvalidOutputs.map(
        (question: IQuestion) => question.initialKey
      ),
  };
};

const isEditQuestionOutputValid = (
  editQuestion: IQuestion,
  currentQuestion: IQuestion
) => {
  switch (editQuestion.type) {
    case QuestionType.LIST:
      return isListQuestionOutputValid(editQuestion, currentQuestion);
    case QuestionType.TABLE:
      return isTableQuestionOutputValid(editQuestion, currentQuestion);
    default:
      return true;
  }
};

const isListQuestionOutputValid = (
  editQuestion: IQuestion,
  currentQuestion: IQuestion
) => {
  // Convert the outputs object into an array, based on the order of keys in 'inputs'
  const outputsArray = currentQuestion.inputs.map(
    (input) => editQuestion.outputs[input]
  );

  // Filter out null or undefined values from outputsArray
  const filteredOutputsArray = outputsArray.filter(
    (output) => output !== null && output !== undefined
  );

  // Check if the filteredOutputsArray exists as an option in currentQuestion.options
  const isValidOutput = currentQuestion.options.some(
    (option: any) =>
      JSON.stringify(option.slice(0, filteredOutputsArray.length)) ===
      JSON.stringify(filteredOutputsArray)
  );

  return isValidOutput;
};
const isTableQuestionOutputValid = (
  editQuestion: IQuestion,
  currentQuestion: IQuestion
) => {
  // Convert the outputs object into an array, based on the order of keys in 'inputs'
  const outputsArray = currentQuestion.inputs.map(
    (input) => editQuestion.outputs[input]
  );

  // Filter out null or undefined values from outputsArray
  const filteredOutputsArray = outputsArray.filter(
    (output) => output !== null && output !== undefined
  );

  // Check if the filteredOutputsArray exists as an option in currentQuestion.options
  const isValidOutput = currentQuestion.options.some(
    (option: any) =>
      JSON.stringify(option.slice(0, filteredOutputsArray.length)) ===
      JSON.stringify(filteredOutputsArray)
  );

  return isValidOutput;
};
