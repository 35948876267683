import Button from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { Container, PageNotAuthorizedLabel } from './PageNotAuthorized.styled';
import { useTranslation } from 'react-i18next';

const PageNotAuthorized = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Container>
      <PageNotAuthorizedLabel>
        {t('You are not authorized')}
      </PageNotAuthorizedLabel>
      <Button
        primary
        label={'Return'}
        width={'150rem'}
        onClick={() => navigate('/')}
      />
    </Container>
  );
};

export default PageNotAuthorized;
