import ReactSelect from 'react-select';
import { IOption } from './type';
import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { borderRadiusSm } from 'assets/styled';
import { CSSProperties } from 'react';
import { respondTo } from 'assets/styled/breakpoints';

interface StyledProps {
  isError: boolean;
  hasImages: boolean;
  height: string;
  width: string;
  styles?: CSSProperties;
}

export const selectStyles = css`
  .select__option {
    font-size: 16rem;
    color: ${COLORS.BLACK} !important;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border-bottom: 1px solid ${COLORS.BORDER};
    max-height: auto;
    overflow-y: hidden;

    &:hover {
      background-color: ${COLORS.GRAY_300} !important;
    }
  }

  .select__option--is-selected {
    background-color: ${COLORS.FORM_ELEMENT_HOVER} !important;
  }

  .select__placeholder {
    ${respondTo.bigPhone`
      font-size: 14rem;
    `};
  }

  .select__single-value {
    color: ${COLORS.BLACK};
  }

  .select__control {
    cursor: pointer;
    font-size: 16rem;
    transition: all 0.3s ease-in-out;
    border-radius: ${borderRadiusSm};
    &:hover {
      background-color: ${COLORS.FORM_ELEMENT_HOVER};
      border: 1rem solid ${COLORS.GREEN};
    }
  }

  .select__control--is-focused {
    box-shadow: 3rem 3rem 5rem ${COLORS.BORDER};
    border: 1rem solid ${COLORS.GREEN};
  }

  .select__control--is-disabled {
    cursor: not-allowed;
  }

  .select__multi-value {
    border-radius: ${borderRadiusSm};
  }

  .select__multi-value__label {
    border-radius: ${borderRadiusSm};
  }

  .select__multi-value__remove {
    border-top-right-radius: ${borderRadiusSm};
    border-bottom-right-radius: ${borderRadiusSm};
  }

  .select__clear-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    fill: red;
  }

  .select__indicator-separator {
    display: none;
  }

  .select__dropdown-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .select__menu {
    border-radius: ${borderRadiusSm} !important;
    overflow: hidden;
    background-color: ${COLORS.WHITE} !important;
    border: 1rem solid ${COLORS.BORDER} !important;
    z-index: 2;
    ${respondTo.smallPhone`
        max-height: 50vh;
      `}
  }

  .select__menu-list {
    max-height: 400rem;
    padding: 0;
  }

  .select__option--is-focused {
    background-color: ${COLORS.WHITE} !important;
  }
  .controlFocused {
    @extend .control;
    box-shadow: 3rem 3rem 5rem ${COLORS.BORDER};
  }
`;

export const StyledReactSelect = styled(ReactSelect<IOption, boolean>)`
  ${selectStyles}

  ${(props: StyledProps) =>
    props.width &&
    css`
      width: ${(props: StyledProps) => props.width};
    `}

  .select__control {
    ${(props: StyledProps) =>
      props.height &&
      css`
        min-height: ${props.height};
      `};
    background-color: ${COLORS.WHITE};
    ${(props: StyledProps) =>
      props.isError
        ? css`
            border: 1rem solid ${COLORS.RED_100};
          `
        : css`
            border: 1rem solid ${COLORS.BORDER};
          `}
  }

  .select__input-container {
    color: ${COLORS.BLACK};
  }
`;

export const Indicator = styled(`img`)`
  padding: 10rem 8rem;
`;

export const ErrorMessageWrapper = styled.div`
  position: absolute;
  font-weight: 400;
  font-size: 12rem;
  line-height: 10rem;
  color: ${COLORS.RED_100};
  ${(props: StyledProps) =>
    props.hasImages
      ? css`
          top: -26rem;
          right: 0rem;
        `
      : css`
          bottom: -15rem;
          right: 0rem;

          ${respondTo.smallTablet`
            bottom: -13rem;
          `}
        `}
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  ${({ styles }: StyledProps) => styles}

  ${respondTo.smallTablet`
    width: 100%;
  `}
`;

export const RelativeDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
