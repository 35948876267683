import {
  RESET_EDIT_CONFIGURATION_VALIDATION_WARNINGS,
  SET_APP_MODE,
  SET_EDIT_CONFIGURATION_VALIDATION_WARNINGS,
  SET_IS_DARK_MODE,
  SET_IS_LOADING_SCREEN_SHOWN,
  SET_IS_MOBILE_APP,
  SET_QUERY_PARAMS_PROCESSED,
} from 'store/constants';
import { AppMode } from '../reducers/common';
import { IQuestionDifferences } from 'providers/QueryProvider/validation';

export const setEditConfigurationValidationWarnings = (
  editConfigurationValidationWarnings: IQuestionDifferences
) => ({
  type: SET_EDIT_CONFIGURATION_VALIDATION_WARNINGS,
  payload: editConfigurationValidationWarnings,
});

export const resetEditConfigurationValidationWarnings = () => ({
  type: RESET_EDIT_CONFIGURATION_VALIDATION_WARNINGS,
});

export const setDarkMode = (darkMode: boolean) => ({
  type: SET_IS_DARK_MODE,
  payload: darkMode,
});

export const setAppMode = (appMode: AppMode) => ({
  type: SET_APP_MODE,
  payload: appMode,
});

export const setQueryParamsProcessed = (boolean: boolean) => ({
  type: SET_QUERY_PARAMS_PROCESSED,
  payload: boolean,
});

export const setIsMobileApp = (isMobileApp: boolean) => ({
  type: SET_IS_MOBILE_APP,
  payload: isMobileApp,
});

export const setIsLoadingScreenShown = (boolean: boolean) => ({
  type: SET_IS_LOADING_SCREEN_SHOWN,
  payload: boolean,
});
