import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { duplicateCartLine } from 'services/ShoppingCartService';
import { LogyxError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useDuplicateCartLine = () => {
  const { t } = useTranslation();
  return useMutation((cartLineId: number) => duplicateCartLine(cartLineId), {
    onSuccess: () => {
      toast.success(t('Successfully duplicated shopping cart line'));
      queryClient.invalidateQueries([ReactQueryKeys.GET_SHOPPING_CART]);
    },
    onError: (error: LogyxError) => {
      toast.error(getToastErrorMessage(error));
    },
    onSettled: () => {
      // Finally
    },
    mutationKey: ReactMutationKeys.DUPLICATE_CART_LINE,
  });
};
