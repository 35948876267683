import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoadingScreenShown } from 'store/Common/actions/common';
import { IRootReducerState } from 'store/store';

// Currently used only for intent logyx
export const useHandleLoadingScreenState = () => {
  const dispatch = useDispatch();
  const { isLoadingScreenShown } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );

  const company = useSelector(
    (state: IRootReducerState) => state.userInfo.company
  );
  const companyFetched = !!company?.id;

  const supplier = useSelector(
    (state: IRootReducerState) => state.userInfo.supplier
  );
  const supplierFetched = !!supplier?.id;

  // State to track if the minimum display time has passed
  const [minTimePassed, setMinTimePassed] = useState(false);

  // Set a timer to mark the minimum display time
  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (isLoadingScreenShown) {
      timer = setTimeout(() => {
        setMinTimePassed(true);
      }, 1300);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    }; // Cleanup the timer on component unmount
  }, [isLoadingScreenShown]);

  // Effect to hide the loading screen when both conditions are met
  useEffect(() => {
    if (
      isLoadingScreenShown &&
      companyFetched &&
      supplierFetched &&
      minTimePassed
    ) {
      dispatch(setIsLoadingScreenShown(false));
    }
  }, [
    isLoadingScreenShown,
    companyFetched,
    supplierFetched,
    minTimePassed,
    dispatch,
  ]);

  useEffect(() => {
    if (!isLoadingScreenShown && minTimePassed) {
      setMinTimePassed(false);
    }
  }, [isLoadingScreenShown, minTimePassed]);
};
