import { AnyAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { UseMutateFunction } from 'react-query';
import { LogyxError } from 'services/api/errors';
import { ISelectEntitiesDTO } from './hooks';
import LocalStorageService from 'services/LocalStorageService';
import { LOCAL_STORAGE_ACCESS_TOKEN } from 'services/api/constants';
import { setIsLoggedIn, setUserInfo } from 'store/User/actions/user';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import { IUserData } from 'store/User/types';

export const handleSelectEntities = (
  dispatch: Dispatch<AnyAction>,
  selectEntities: UseMutateFunction<
    any,
    LogyxError,
    ISelectEntitiesDTO,
    unknown
  >,
  companyId?: number,
  supplierId?: number
) => {
  const dto: ISelectEntitiesDTO = {
    companyId: companyId,
    supplierId: supplierId,
  };
  selectEntities(dto, {
    onSuccess: async (data) => {
      if (data?.access_token) {
        decodeTokenAndSetUserData(data.access_token, dispatch);
        await LocalStorageService.setItem(
          LOCAL_STORAGE_ACCESS_TOKEN,
          data.access_token
        );
      }
      dispatch(setIsLoggedIn(true));
    },
  });
};

export const decodeTokenAndSetUserData = (
  accessToken: string,
  dispatch: Dispatch<AnyAction>
) => {
  const decodedToken: any = jwtDecode<JwtPayload>(accessToken);
  if (!decodedToken?.sub) {
    alert('Error decoding token');
    return;
  }
  const userData: IUserData = {
    id: decodedToken.sub.id,
    email: decodedToken.sub.email,
    first_name: decodedToken.sub.first_name,
    last_name: decodedToken.sub.last_name,
    is_admin: decodedToken.sub.is_admin,
    permissions: decodedToken.sub.permissions,
  };
  dispatch(setUserInfo(userData));
};
