import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IUserPermissions } from 'store/User/types';
import { IRootReducerState } from 'store/store';
import {
  PermissionGroupCompanyId,
  PermissionGroupSupplierId,
} from 'types/Permissions.types';

const useHasRole = (
  companyPermissionGroupId?: PermissionGroupCompanyId,
  supplierPermissionGroupId?: PermissionGroupSupplierId
) => {
  const permissions: IUserPermissions = useSelector(
    (state: IRootReducerState) => state.userInfo.permissions
  );

  // Admins have all permission groups except the ones that arent allowed by the license of the current company or supplier
  const hasRole = useMemo(() => {
    if (companyPermissionGroupId && !permissions?.company?.group_ids)
      return false;
    if (supplierPermissionGroupId && !permissions?.supplier?.group_ids)
      return false;

    let hasRole = false;

    if (companyPermissionGroupId) {
      hasRole = !!permissions.company.group_ids.find(
        (companyGroupId: number) => companyGroupId === companyPermissionGroupId
      );
    }

    if (supplierPermissionGroupId) {
      hasRole = !!permissions.supplier.group_ids.find(
        (supplierGroupId: number) =>
          supplierGroupId === supplierPermissionGroupId
      );
    }

    return hasRole;
  }, [companyPermissionGroupId, supplierPermissionGroupId, permissions]);

  return hasRole;
};

export default useHasRole;
