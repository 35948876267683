import { capitalizeFirstLetter } from './stringUtils';

export const isValidEmail = (email: string) => {
  const re = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}/;
  return re.test(String(email).toLowerCase());
};

export const isNumeric = (num: any) =>
  (typeof num === 'number' || (typeof num === 'string' && num.trim() !== '')) &&
  !isNaN(num as number);

export function validateOptionalNumericField(
  value: string,
  fieldName: string,
  min: number | null,
  max: number | null,
  t: (str: string) => string
) {
  if (value === '' || value === undefined || value === null) {
    return null;
  } else if (!isNumeric(value)) {
    return t('{{FIELD}} must be a number').replace(
      '{{FIELD}}',
      capitalizeFirstLetter(fieldName)
    );
  }
  if (min !== null && Number(value) < min) {
    return t("{{FIELD}} can't be smaller than {{VALUE}}")
      .replace('{{FIELD}}', capitalizeFirstLetter(fieldName))
      .replace('{{VALUE}}', min.toString());
  } else if (max !== null && Number(value) > max) {
    return t("{{FIELD}} can't be larger than {{VALUE}}")
      .replace('{{FIELD}}', capitalizeFirstLetter(fieldName))
      .replace('{{VALUE}}', max.toString());
  }
}

export function validateNumericField(
  value: string,
  fieldName: string,
  min: number,
  max: number,
  t: (str: string) => string
) {
  if (value === '' || value === undefined || value === null) {
    return t('{{FIELD}} is invalid').replace(
      '{{FIELD}}',
      capitalizeFirstLetter(fieldName)
    );
  } else if (!isNumeric(value)) {
    return t('{{FIELD}} must be a number').replace(
      '{{FIELD}}',
      capitalizeFirstLetter(fieldName)
    );
  }
  if (min !== null && Number(value) < min) {
    return t("{{FIELD}} can't be smaller than {{VALUE}}")
      .replace('{{FIELD}}', capitalizeFirstLetter(fieldName))
      .replace('{{VALUE}}', min.toString());
  } else if (max !== null && Number(value) > max) {
    return t("{{FIELD}} can't be larger than {{VALUE}}")
      .replace('{{FIELD}}', capitalizeFirstLetter(fieldName))
      .replace('{{VALUE}}', max.toString());
  }
}

export function validateField(
  value: string,
  fieldName: string,
  t: (str: string) => string
) {
  if (value === '' || value === undefined || value === null) {
    return t('{{FIELD}} is invalid').replace(
      '{{FIELD}}',
      capitalizeFirstLetter(fieldName)
    );
  }
}

export function validateRadioInput(
  value: string,
  fieldName: string,
  t: (str: string) => string
) {
  if (value === '' || value === undefined || value === null) {
    return t('{{FIELD}} must be selected').replace(
      '{{FIELD}}',
      capitalizeFirstLetter(fieldName)
    );
  }
}

export function validateTableInput(
  value: any,
  fieldName: string,
  t: (str: string) => string
) {
  if (
    value === '' ||
    value === undefined ||
    value === null ||
    (Array.isArray(value) && !value.length)
  ) {
    return t('{{FIELD}} must be selected').replace(
      '{{FIELD}}',
      capitalizeFirstLetter(fieldName)
    );
  }
}

export function validateColorInput(
  value: any,
  fieldName: string,
  t: (str: string) => string
) {
  if (
    value === '' ||
    value === undefined ||
    value === null ||
    (Array.isArray(value) && !value.length)
  ) {
    return t('{{FIELD}} must be selected').replace(
      '{{FIELD}}',
      capitalizeFirstLetter(fieldName)
    );
  }
}
