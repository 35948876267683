import { queryClient } from 'index';
import { RoutesConfig } from 'navigation/routes';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { getToastErrorMessage, LogyxError } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { createCustomModel } from 'services/SupplierService';
import { useNavigateWithQuery } from 'utils/hooks/useNavigateWithQuery';

export const useCreateCustomModel = (supplierId: number) => {
  const { t } = useTranslation();
  const navigate = useNavigateWithQuery();
  return useMutation(
    () => {
      return createCustomModel(supplierId);
    },
    {
      onSuccess: (data) => {
        toast.success(t('New custom model created'));
        queryClient.invalidateQueries([ReactQueryKeys.GET_MODELS]);
        navigate(
          `${RoutesConfig.Builder.fullPath.replace(':id', data.version_id)}`
        );
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey: ReactMutationKeys.CREATE_CUSTOM_MODEL,
    }
  );
};
