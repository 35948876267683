import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import {
  ButtonWrapper,
  CategoriesList,
  CategoryActions,
  CategoryItem,
  CategoryLabel,
  Container,
  InputWrapper,
  Title,
} from './ManageCategoriesModal.styled';
import { Modal } from '../Modal/Modal';
import { useTranslation } from 'react-i18next';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import {
  MagnifyingGlass as Search,
  PencilSimple,
  Trash,
} from '@phosphor-icons/react';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { useLoadNextPage } from 'utils/hooks/useLoadNextPage';
import { useDebounce } from 'utils/hooks/useDebounce';
import { useGetCategoryOptions } from 'pages/AllModelsPage/hooks';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import { useDeleteCategory } from './hooks';
import { DangerModal } from '../DangerModal/DangerModal';
import AddEditCategoryModal from '../AddEditCategoryModal/AddEditCategoryModal';

export interface IManageCategoriesModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export function ManageCategoriesModal({
  isOpen,
  setIsOpen,
}: IManageCategoriesModalProps) {
  const { t } = useTranslation();
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] =
    useState<boolean>(false);
  const [isAddEditCategoryModal, setIsAddEditCategoryModal] =
    useState<boolean>(false);
  const [isEditCategory, setIsEditCategory] = useState<boolean>(false);

  const [categoryIdForDelete, setCategoryIdForDelete] = useState<string>('');
  const [categoryIdForEdit, setCategoryIdForEdit] = useState<string>('');
  const [categoryLabelForEdit, setCategoryLabelForEdit] = useState<string>('');

  const [categoriesSearchBy, setCategoriesSearchBy] = useState<string>('');
  const debouncedCategoriesSearchBy = useDebounce(categoriesSearchBy);

  const { categoryOptions, fetchNextPage, hasNextPage } = useGetCategoryOptions(
    debouncedCategoriesSearchBy
  );

  const { mutate: deleteCategory, isLoading: isLoadingdeleteCategory } =
    useDeleteCategory();

  useLoadNextPage(loadMoreRef, hasNextPage, fetchNextPage, false);

  useEffect(() => {
    if (!isOpen) {
      setCategoriesSearchBy('');
    }
  }, [isOpen]);

  const { isSmallPhone } = useBreakpointFlag();

  const clearEditForm = () => {
    setCategoryIdForDelete('');
    setCategoryIdForEdit('');
    setCategoryLabelForEdit('');
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      modalStyle={{
        margin: 'auto',
        width: isSmallPhone ? '90%' : '600rem',
        minWidth: isSmallPhone ? '90%' : 'auto',
        position: 'fixed',
      }}
    >
      <Container>
        <Title>{t('Manage product categories')}</Title>
        <InputWrapper>
          <Input
            icon={Search}
            placeholder={t('Search')}
            width={'100%'}
            wrapperStyles={{ width: '100%' }}
            onChange={(e: any) => setCategoriesSearchBy(e.target.value)}
          />
        </InputWrapper>
        <CategoriesList>
          {categoryOptions.map((category, index) => {
            return (
              <CategoryItem key={index}>
                <CategoryLabel>{category.label}</CategoryLabel>
                {category.value !== '' && (
                  <CategoryActions>
                    <Icon
                      size={22}
                      svg={PencilSimple}
                      color={COLORS.GREEN}
                      onClick={() => {
                        setIsEditCategory(true);
                        setCategoryIdForEdit(category.value);
                        setCategoryLabelForEdit(category.label);
                        setIsAddEditCategoryModal(true);
                      }}
                    />
                    <Icon
                      size={22}
                      svg={Trash}
                      color={COLORS.RED_100}
                      onClick={() => {
                        setIsDeleteUserModalOpen(true);
                        setCategoryIdForDelete(category.value);
                      }}
                      disabled={isLoadingdeleteCategory}
                    />
                  </CategoryActions>
                )}
              </CategoryItem>
            );
          })}
          <div
            ref={loadMoreRef}
            style={{
              height: '1px',
            }}
          />
        </CategoriesList>
        <ButtonWrapper>
          <Button
            label={t('Add new category')}
            primary
            width="100%"
            wrapperStyle={{ width: '100%' }}
            onClick={() => {
              setIsEditCategory(false);
              setIsAddEditCategoryModal(true);
              clearEditForm();
            }}
          />
        </ButtonWrapper>

        <DangerModal
          level="SECOND"
          isOpen={isDeleteUserModalOpen}
          setIsOpen={setIsDeleteUserModalOpen}
          title={t('Confirm deletion')}
          submit={{
            onClick: () => {
              deleteCategory(categoryIdForDelete!);
              setIsDeleteUserModalOpen(false);
              clearEditForm();
            },
            text: t('Delete'),
            disabled: isLoadingdeleteCategory,
          }}
          cancel={{
            onClick: () => {
              setIsDeleteUserModalOpen(false);
              clearEditForm();
            },
          }}
          description={t('Are you sure you want to delete this category?')}
        />

        {isAddEditCategoryModal && (
          <AddEditCategoryModal
            isOpen={isAddEditCategoryModal}
            setIsOpen={setIsAddEditCategoryModal}
            id={categoryIdForEdit}
            label={categoryLabelForEdit}
            isEdit={isEditCategory}
          />
        )}
      </Container>
    </Modal>
  );
}
