import PageNotAuthorized from 'pages/PageNotAuthorized/PageNotAuthorized';
import React from 'react';
import {
  PermissionGroupCompanyId,
  PermissionGroupSupplierId,
} from 'types/Permissions.types';
import useHasRole from 'utils/hooks/useHasRole';

interface IProps {
  companyPermissionGroupId?: PermissionGroupCompanyId;
  supplierPermissionGroupId?: PermissionGroupSupplierId;
  children?: React.ReactNode | React.ReactNode[];
}

const ProtectedRoute: React.FC<IProps> = ({
  companyPermissionGroupId,
  supplierPermissionGroupId,
  children,
}) => {
  const hasPermission = useHasRole(
    companyPermissionGroupId,
    supplierPermissionGroupId
  );

  if (!hasPermission) {
    return <PageNotAuthorized />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
