import apiClient from './api/apiService';

const BASE_URL = '/v1/intents';

export const getCompanySupplierPermissionsAndIntent =
  async (): Promise<any> => {
    const url = `${BASE_URL}/me`;
    const { data } = await apiClient.get<any>(url);

    return data.data;
  };
