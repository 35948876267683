import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { getToastErrorMessage, LogyxError } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { saveDraft } from 'services/BuilderService';
import { ICustomModel } from 'types/Builder.types';
import { useEffect, useState } from 'react';
import { useCallbackPrompt } from 'utils/hooks/useCallbackPrompt';

export const useSaveDraft = (
  model: ICustomModel,
  versionId: number,
  onSuccess?: () => void,
  onError?: () => void
) => {
  const { t } = useTranslation();
  return useMutation(
    () => {
      return saveDraft(model, versionId);
    },
    {
      onSuccess: () => {
        toast.success(t('Draft saved'));
        queryClient.invalidateQueries([ReactQueryKeys.GET_VERSIONS]);
        if (onSuccess) onSuccess();
      },
      onError: (error: LogyxError) => {
        const errorMessage = getToastErrorMessage(error);
        const validationErrorMessage = t('Validation error');
        toast.error(
          typeof errorMessage === 'string'
            ? errorMessage
            : validationErrorMessage,
          {
            toastId: ReactMutationKeys.SAVE_DRAFT,
          }
        );
        if (onError) onError();
      },
      mutationKey: ReactMutationKeys.SAVE_DRAFT,
    }
  );
};

export const useCheckForSaveBeforeUserLeave = (isModelChanged: boolean) => {
  const [checkNavigation, setCheckNavigation] = useState<boolean>(false);
  const { showPrompt, confirmNavigation, cancelNavigation } =
    useCallbackPrompt(checkNavigation);

  useEffect(() => {
    setCheckNavigation(isModelChanged);
  }, [isModelChanged]);

  return {
    showPrompt,
    confirmNavigation,
    cancelNavigation,
    setCheckNavigation,
  };
};
