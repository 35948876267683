import {
  AbsoluteItem,
  AbsoluteWrapper,
  Container,
  Logo,
  RelativeWrapper,
  SelectCompanyLabel,
} from './CompanySelectPage.styled';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  useGetCompaniesInfinite,
  useHasSupplierOptionsAvailable,
  useSelectEntities,
} from './hooks';
import { ICompany } from 'types/Company.types';
import LogyxLogoSVG from 'assets/icons/logyxLogomark.svg';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import { setCompany, setSupplier } from 'store/User/actions/user';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutesConfig } from 'navigation/routes';
import ToggleDarkMode from 'components/ToggleDarkMode/ToggleDarkMode';
import { useDebounce } from 'utils/hooks/useDebounce';
import { usePageTitle } from 'utils/hooks/usePageTitle';
import { setAppMode } from 'store/Common/actions/common';
import { AppMode } from 'store/Common/reducers/common';
import ScrollableList from 'components/ScrollableList/ScrollableList';
import { COMPANIES_PER_PAGE } from './constants';
import { getDataFromPages } from 'utils/getDataFromPages';
import { LOGOUT } from 'store/constants';
import { toast } from 'react-toastify';
import { handleSelectEntities } from './helpers';

const CompanySelectPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchByCompany, setSearchByCompany] = useState<string>('');
  const debouncedSearchByCompany = useDebounce(searchByCompany);

  const {
    data: companiesData,
    isLoading: isLoadingGetCompanies,
    isSuccess: isSuccessGetCompanies,
    hasNextPage,
    fetchNextPage,
    isFetching: isFetchingGetCompanies,
  } = useGetCompaniesInfinite(COMPANIES_PER_PAGE, debouncedSearchByCompany);

  const companies: ICompany[] = getDataFromPages(
    companiesData?.pages,
    'companies'
  );

  const { suppliers, isLoadingSuppliers } = useHasSupplierOptionsAvailable();
  const hasSupplierOptionsAvailable = suppliers?.length;

  const { mutate: selectEntities, isLoading: isLoadingSelectEntities } =
    useSelectEntities();

  const handleSupplierOptionsAreAvailable = (
    shouldSetAppModeToSupplier: boolean,
    selectedCompanyId?: number
  ) => {
    if (shouldSetAppModeToSupplier) {
      dispatch(setAppMode(AppMode.SUPPLIER));
    }
    if (suppliers?.length === 1) {
      dispatch(setSupplier(suppliers[0]));
      handleSelectEntities(
        dispatch,
        selectEntities,
        selectedCompanyId,
        suppliers[0].id
      );
    } else {
      navigate(RoutesConfig.SupplierSelect.fullPath);
    }
  };

  useEffect(() => {
    // Wait until suppliers are fetched
    if (!isLoadingSuppliers) {
      // If only 1 company is available, select it
      if (isSuccessGetCompanies && debouncedSearchByCompany === '') {
        const companyCount = companies?.length;
        dispatch(setAppMode(AppMode.COMPANY));
        switch (companyCount) {
          case 1:
            {
              dispatch(setCompany(companies[0]));
              // After selecting the company , check if there are suppliers available
              if (hasSupplierOptionsAvailable) {
                handleSupplierOptionsAreAvailable(false, companies[0].id);
              } else {
                handleSelectEntities(dispatch, selectEntities, companies[0].id);
              }
            }
            break;
          case 0: {
            if (hasSupplierOptionsAvailable) {
              handleSupplierOptionsAreAvailable(true);
            } else {
              navigate(RoutesConfig.Login.fullPath);
              dispatch({ type: LOGOUT });
              toast.error(
                t('User is not connected to any company or supplier')
              );
            }
          }
        }
      }
    }
  }, [isSuccessGetCompanies, isLoadingSuppliers]);

  usePageTitle('Select company');

  return (
    <Container>
      <Logo src={LogyxLogoSVG} alt="" />
      <SelectCompanyLabel>{t('Select company')}</SelectCompanyLabel>
      <RelativeWrapper>
        <AbsoluteWrapper>
          <AbsoluteItem>
            <ToggleDarkMode />
          </AbsoluteItem>
          <AbsoluteItem>
            <LanguageSwitcher />
          </AbsoluteItem>
        </AbsoluteWrapper>
      </RelativeWrapper>
      <ScrollableList
        items={companies || []}
        onClick={(company: ICompany) => {
          if (isLoadingSelectEntities) {
            return;
          }
          dispatch(setAppMode(AppMode.COMPANY));
          dispatch(setCompany(company));
          if (hasSupplierOptionsAvailable) {
            navigate(RoutesConfig.SupplierSelect.fullPath);
          } else {
            handleSelectEntities(dispatch, selectEntities, company.id);
          }
        }}
        onSearch={(str: string) => setSearchByCompany(str)}
        noContentLabel={t('No companies found')}
        searchLabel={t('Search companies')}
        isLoading={isLoadingGetCompanies || isLoadingSuppliers}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetching={isFetchingGetCompanies}
      />
    </Container>
  );
};

export default CompanySelectPage;
