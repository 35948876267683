import { ICartItem } from 'types/CartItem.types';
import {
  Container,
  EditIconLabelWrapper,
  EditLabel,
  Grid,
  HorizontalLine,
  LabelKey,
  LabelValue,
  NameLabel,
  PriceLabel,
  PriceValueLabel,
  RemoveIconLabelWrapper,
  RemoveLabel,
  RowSpaceBetween,
  FirstRow,
  RightPart,
  LeftPart,
  DuplicateIconLabelWrapper,
  DuplicateLabel,
  ImagesConfigurationWrapper,
  GridPriceWrapper,
  PriceWrapper,
} from './ShoppingCartCard.styled';
import { useDuplicateCartLine } from './hooks';
import { parseQuestionsObjectResponseIntoArray } from 'store/Model/reducers/model';
import { calculateTotalNetPrice, handleTrueFalseLabel } from './helpers';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setImages, setPricing, setQuestions } from 'store/Model/actions/model';
import { useNavigate } from 'react-router-dom';
import { setCurrentlyEditingShoppingCartLineId } from 'store/ShoppingCart/actions/shoppingCart';
import FormImages from 'pages/ConfigurePage/FormImages/FormImages';
import {
  IImageSection,
  useFilterOutHiddenImages,
} from 'pages/ConfigurePage/FormImages/hooks';
import { RoutesConfig } from 'navigation/routes';
import Icon from 'components/Icon/Icon';
import { Copy, PencilSimple, Trash } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import { formatValuesFromOutputs } from 'pages/ConfigurePage/ConfigureForm/helpers';
import { IQuestion } from 'types/Question.types';
import { useGetValuesFromQuestionOutputs } from 'pages/ConfigurePage/ConfigureForm/hooks';

interface IShoppingCartCardProps {
  item: ICartItem;
  onRemove: () => void;
  isLast: boolean;
}

const ShoppingCartCard = ({
  item,
  onRemove,
  isLast,
}: IShoppingCartCardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const questions: IQuestion[] = parseQuestionsObjectResponseIntoArray(
    item.configuration.questions
  );
  const valuesFromOutputs = useGetValuesFromQuestionOutputs(questions);
  const formattedValuesFromOutputs = formatValuesFromOutputs(
    valuesFromOutputs,
    questions
  );

  const totalNetPrice = calculateTotalNetPrice(item.configuration.pricing);

  const { mutate: duplicateCartLine } = useDuplicateCartLine();

  const hasImages =
    item.configuration.images && Object.keys(item.configuration.images).length;

  const visibleImages: IImageSection[] = useFilterOutHiddenImages(
    item.configuration.images
  );

  return (
    <Container>
      <FirstRow>
        <LeftPart>
          <NameLabel>{item.configuration.general.name}</NameLabel>
        </LeftPart>
        <RightPart>
          <EditIconLabelWrapper
            onClick={() => {
              dispatch(setQuestions(item.configuration.questions));
              dispatch(setImages(item.configuration.images));
              dispatch(setPricing(item.configuration.pricing));
              dispatch(setCurrentlyEditingShoppingCartLineId(item.id));
              navigate(
                `${RoutesConfig.Configure.fullPath}?modelId=${item.model_id}`
              );
            }}
          >
            <Icon
              svg={PencilSimple}
              color={COLORS.GREEN}
              onClick={() => null}
            />
            <EditLabel onClick={() => null}>{t('Edit')}</EditLabel>
          </EditIconLabelWrapper>
          <RemoveIconLabelWrapper onClick={() => onRemove()}>
            <Icon svg={Trash} color={COLORS.RED_100} onClick={() => null} />
            <RemoveLabel onClick={() => onRemove()}>{t('Remove')}</RemoveLabel>
          </RemoveIconLabelWrapper>
          <DuplicateIconLabelWrapper onClick={() => duplicateCartLine(item.id)}>
            <Icon svg={Copy} color={COLORS.GREEN} onClick={() => null} />
            <DuplicateLabel>{t('Duplicate')}</DuplicateLabel>
          </DuplicateIconLabelWrapper>
        </RightPart>
      </FirstRow>
      <ImagesConfigurationWrapper>
        {hasImages ? (
          <FormImages visibleImages={visibleImages} singleImage />
        ) : null}
        <GridPriceWrapper>
          <Grid hasImages={hasImages}>
            {Object.keys(formattedValuesFromOutputs)?.map(
              (dataKey: string, index: number) => (
                <RowSpaceBetween key={index}>
                  <LabelKey>{dataKey}</LabelKey>
                  <LabelValue>
                    {handleTrueFalseLabel(
                      formattedValuesFromOutputs[dataKey]?.toString(),
                      t
                    )}
                  </LabelValue>
                </RowSpaceBetween>
              )
            )}
          </Grid>
          <PriceWrapper>
            <PriceLabel hasImages={hasImages}>
              {t('Price')}:
              <PriceValueLabel>
                &nbsp;
                {totalNetPrice !== null
                  ? '€' + totalNetPrice.toFixed(2)
                  : 'Unknown price'}
              </PriceValueLabel>
            </PriceLabel>
          </PriceWrapper>
        </GridPriceWrapper>
      </ImagesConfigurationWrapper>
      {isLast ? null : <HorizontalLine hasImages={hasImages} />}
    </Container>
  );
};
export default ShoppingCartCard;
