import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components/Modals/Modal/Modal';
import {
  ButtonsWrapper,
  ButtonWrapper,
  Container,
  Description,
  Row,
  RowTitle,
  RowTitleWrapper,
  Title,
} from './ImportExportPriceModal.styled';
import Tooltip from 'components/Tooltip/Tooltip';
import Icon from 'components/Icon/Icon';
import { Info } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import Button from 'components/Button/Button';
import { ICustomModel } from 'types/Builder.types';
import { FileUploadModal } from 'components/Modals/FileUploadModal/FileUploadModal';
import { priceMatrixFileType } from '../../constants';
import { useImportPriceMatrix } from '../hooks';
import { exportMatrixTemplate } from '../../helpers';

export interface IImportExportPriceModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onNext: () => void;
  model: ICustomModel;
  setModel: Dispatch<SetStateAction<ICustomModel>>;
}

const ImportExportPriceModal = ({
  isOpen,
  setIsOpen,
  onNext,
  model,
  setModel,
}: IImportExportPriceModalProps) => {
  const { t } = useTranslation();
  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);

  const {
    data: importPriceMatrixData,
    mutate: importPriceMatrix,
    isLoading: isLoadingImportPriceMatrix,
  } = useImportPriceMatrix();

  const validateLocalMatrix = () => {
    return !!(
      model.price_matrices[0].title &&
      model.price_matrices[0].x_axis_id &&
      model.price_matrices[0].y_axis_id
    );
  };

  const handleNextClick = (data?: any) => {
    setModel((prevModel) => ({
      ...prevModel,
      price_matrices: [
        {
          ...prevModel.price_matrices[0],
          matrix: data?.matrix || importPriceMatrixData?.matrix,
          filetype: data?.filetype || importPriceMatrixData?.filetype,
        },
      ],
    }));
    onNext();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        if (!validateLocalMatrix()) {
          setModel((prevModel) => ({
            ...prevModel,
            price_matrices: [
              {
                ...prevModel.price_matrices[0],
                matrix: null,
                filetype: null,
              },
            ],
          }));
        }
      }}
      modalStyle={{
        margin: 'auto',
        height: 'fit-content',
        width: '685rem',
        position: 'fixed',
      }}
    >
      <Container>
        <Title>{t('Import Price Matrix')}</Title>
        <Description>
          {t('Export our Price matrix template here, Did you already do this')}?
        </Description>
        <Description>
          {t('Then import the Matrix and click “Next”')}
        </Description>
        <Row>
          <RowTitleWrapper>
            <RowTitle>{t('Export Price Matrix template')}</RowTitle>
            <Tooltip content={t('Export template to fill in for your case')}>
              <Icon
                svg={Info}
                color={COLORS.GREEN}
                size={20}
                weight="regular"
              />
            </Tooltip>
          </RowTitleWrapper>
          <Button
            secondary
            label={t('Export')}
            width="150rem"
            onClick={exportMatrixTemplate}
          />
        </Row>
        <Row>
          <RowTitleWrapper>
            <RowTitle>{t('Import Price Matrix')}</RowTitle>
            <Tooltip content={t('Import the price matrix to use')}>
              <Icon
                svg={Info}
                color={COLORS.GREEN}
                size={20}
                weight="regular"
              />
            </Tooltip>
          </RowTitleWrapper>
          <ButtonWrapper>
            <Button
              primary
              label={t('Import')}
              isLoading={isLoadingImportPriceMatrix}
              width="150rem"
              onClick={() => {
                setIsUploadModalOpen(true);
              }}
            />
          </ButtonWrapper>
        </Row>
        <ButtonsWrapper>
          <Button
            secondary
            label={t('Cancel')}
            width="150rem"
            onClick={() => setIsOpen(false)}
          />
          <Button
            primary
            label={t('Next')}
            width="150rem"
            disabled={!importPriceMatrixData}
            onClick={() => handleNextClick()}
          />
        </ButtonsWrapper>
      </Container>

      <FileUploadModal
        level={'SECOND'}
        isOpen={isUploadModalOpen}
        setIsOpen={setIsUploadModalOpen}
        onSubmit={async (files: any) => {
          const file = files[0];
          const blob = new Blob([file], { type: file.type });
          importPriceMatrix(blob, {
            onSuccess: (data) => handleNextClick(data),
          });
        }}
        multiple={false}
        customAllowedTypes={priceMatrixFileType}
      />
    </Modal>
  );
};

export default ImportExportPriceModal;
