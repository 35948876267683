import { IModel } from 'types/Model.types';
import {
  Container,
  Label,
  LeftPart,
  ModelNameLabel,
  RightPart,
  StyledIcon,
  favoriteIconStyle,
} from './ModelRow.styled';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon/Icon';
import Tooltip from 'components/Tooltip/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { setModel, setQuestions } from 'store/Model/actions/model';
import { useNavigate } from 'react-router-dom';
import { IRootReducerState } from 'store/store';
import { setCurrentlyEditingShoppingCartLineId } from 'store/ShoppingCart/actions/shoppingCart';
import { RoutesConfig } from 'navigation/routes';
import { AppMode } from 'store/Common/reducers/common';
import { Info, GearSix as SettingsIcon } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import { FavoriteButton } from 'components/ModelCard/FavoriteButton/FavoriteButton';

interface IModelRowProps {
  model: IModel;
  withoutLine: boolean;
  onToggleFavoriteSuccess?: () => void;
  withoutCategory?: boolean;
}

const ModelRow = ({
  model,
  withoutLine,
  onToggleFavoriteSuccess,
  withoutCategory = false,
}: IModelRowProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appMode: AppMode = useSelector(
    (state: IRootReducerState) => state.commonInfo.appMode
  );

  return (
    <Container withoutLine={withoutLine}>
      <LeftPart>
        <ModelNameLabel>{model.general.name}</ModelNameLabel>
        <Tooltip
          content={
            model.general.description ||
            model.general.descriptions.join(', ') ||
            t('No description')
          }
        >
          <Icon svg={Info} size={20} color={COLORS.GREEN} />
        </Tooltip>
      </LeftPart>
      <RightPart>
        {withoutCategory ? null : (
          <Label>
            {t('Category')}: {model.category.name}
          </Label>
        )}
        <Label>
          {t('ID')}: {model.id}
        </Label>
        <Label>
          {t('Version')}: {model.version_number}
        </Label>
        {appMode === AppMode.COMPANY && (
          <Button
            label={t('Open')}
            primary
            height="25rem"
            onClick={() => {
              dispatch(setCurrentlyEditingShoppingCartLineId(null));
              navigate(
                `${RoutesConfig.Configure.fullPath}?modelId=${model.id}`
              );
              dispatch(setModel({ id: model.id }));
              dispatch(setQuestions({}));
            }}
          />
        )}
        {appMode === AppMode.SUPPLIER && (
          <StyledIcon
            size={26}
            svg={SettingsIcon}
            color={COLORS.GREEN}
            onClick={() =>
              navigate(
                RoutesConfig.SupplierModelCompanies.fullPath.replace(
                  ':id',
                  String(model.id)
                ),
                {
                  state: model,
                }
              )
            }
          />
        )}
        <FavoriteButton
          favoriteIconStyle={favoriteIconStyle}
          modelId={model.id}
          initialIsFavorite={model.is_favorite}
          onToggleSuccess={onToggleFavoriteSuccess}
        />
      </RightPart>
    </Container>
  );
};

export default ModelRow;
