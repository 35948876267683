import { Dispatch, SetStateAction, useState } from 'react';
import { Modal } from '../Modal/Modal';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { Container, Title } from './AddEditCategoryModal.styled';
import { useTranslation } from 'react-i18next';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import {
  useAddCategory,
  useEditCategory,
} from '../ManageCategoriesModal/hooks';

export interface IAddEditCategoriesModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  id?: string;
  label?: string;
  isEdit: boolean;
}

const AddEditCategoryModal = ({
  isOpen,
  setIsOpen,
  id,
  label,
  isEdit,
}: IAddEditCategoriesModalProps) => {
  const { isSmallPhone } = useBreakpointFlag();
  const { t } = useTranslation();
  const [categoryLabel, setCategoryLabel] = useState<string>(label ?? '');
  const [hasError, setHasError] = useState<boolean>(false);

  const { mutate: addCategory, isLoading: isLoadingAddCategory } =
    useAddCategory();

  const { mutate: editCategory, isLoading: isLoadingEditCategory } =
    useEditCategory();

  const isLoading = isLoadingEditCategory || isLoadingAddCategory;

  return (
    <Modal
      isOpen={isOpen}
      level="SECOND"
      onClose={() => setIsOpen(false)}
      modalStyle={{
        margin: 'auto',
        width: isSmallPhone ? '90%' : '500rem',
        minWidth: isSmallPhone ? '90%' : 'auto',
        position: 'fixed',
      }}
    >
      <Container>
        <Title>
          {isEdit ? t('Edit product category') : t('Add product category')}
        </Title>
        <Input
          placeholder={t('Category label')}
          width={'100%'}
          wrapperStyles={{ width: '100%' }}
          onChange={(e: any) => {
            const value = e.target.value;
            if (value.length > 255) {
              setHasError(true);
            } else {
              setHasError(false);
              setCategoryLabel(value);
            }
          }}
          defaultValue={isEdit ? label : categoryLabel}
          errorMessage={
            hasError ? t('Category label must not exceed 255 characters') : ''
          }
        />
        <Button
          label={t('Submit')}
          primary
          width="100%"
          disabled={isLoading || hasError}
          wrapperStyle={{ marginTop: '15rem', width: '50%' }}
          onClick={() => {
            if (isEdit && id && label) {
              editCategory({ categoryId: id, label: categoryLabel });
            } else {
              addCategory(categoryLabel);
            }
            setIsOpen(false);
          }}
        />
      </Container>
    </Modal>
  );
};

export default AddEditCategoryModal;
