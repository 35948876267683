import {
  AbsoluteWrapper,
  Container,
  Logo,
  RelativeWrapper,
  SelectSupplierLabel,
  AbsoluteItem,
} from './SupplierSelectPage.styled';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ISupplier } from 'types/Supplier.types';
import LogyxLogoSVG from 'assets/icons/logyxLogomark.svg';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import { useState } from 'react';
import { setSupplier } from 'store/User/actions/user';
import { useGetSuppliersInfinite } from 'pages/ModelsPage/hooks';
import { SUPPLIERS_PER_PAGE } from './constants';
import ToggleDarkMode from 'components/ToggleDarkMode/ToggleDarkMode';
import { useDebounce } from 'utils/hooks/useDebounce';
import { usePageTitle } from 'utils/hooks/usePageTitle';
import ScrollableList from 'components/ScrollableList/ScrollableList';
import { getDataFromPages } from 'utils/getDataFromPages';
import { useSelectEntities } from '../CompanySelectPage/hooks';
import { IRootReducerState } from 'store/store';
import { handleSelectEntities } from '../CompanySelectPage/helpers';

const SupplierSelectPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchBySupplier, setSearchBySupplier] = useState<string>('');
  const debouncedSearchBySupplier = useDebounce(searchBySupplier);

  const {
    data: suppliersData,
    isLoading: isLoadingGetSuppliers,
    isFetching: isFetchingGetSuppliers,
    hasNextPage,
    fetchNextPage,
  } = useGetSuppliersInfinite(SUPPLIERS_PER_PAGE, debouncedSearchBySupplier);

  const suppliers: ISupplier[] = getDataFromPages(
    suppliersData?.pages,
    'suppliers'
  );

  usePageTitle('Select supplier');

  const { company } = useSelector((state: IRootReducerState) => state.userInfo);
  const { mutate: selectEntities, isLoading: isLoadingSelectEntities } =
    useSelectEntities();

  return (
    <Container>
      <Logo src={LogyxLogoSVG} alt="" />
      <SelectSupplierLabel>{t('Select supplier')}</SelectSupplierLabel>
      <RelativeWrapper>
        <AbsoluteWrapper>
          <AbsoluteItem>
            <ToggleDarkMode />
          </AbsoluteItem>
          <AbsoluteItem>
            <LanguageSwitcher />
          </AbsoluteItem>
        </AbsoluteWrapper>
      </RelativeWrapper>
      <ScrollableList
        items={suppliers || []}
        onClick={(supplier: ISupplier) => {
          if (isLoadingSelectEntities) {
            return;
          }
          dispatch(setSupplier(supplier));
          handleSelectEntities(
            dispatch,
            selectEntities,
            company?.id,
            supplier.id
          );
        }}
        onSearch={(str: string) => setSearchBySupplier(str)}
        noContentLabel={t('No suppliers found')}
        searchLabel={t('Search suppliers')}
        isLoading={isLoadingGetSuppliers}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetching={isFetchingGetSuppliers}
      />
    </Container>
  );
};

export default SupplierSelectPage;
