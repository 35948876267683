import {
  ActionButton,
  ActionText,
  ButtonsWrapper,
  Container,
  Date,
  DateText,
  IconWrapper,
  LeftPart,
  MidPart,
  Options,
  RightPart,
  StateText,
  StateWrapper,
  Status,
  Version,
  VersionNumber,
  VersionText,
} from './VersionRow.styled';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon/Icon';
import {
  Copy,
  LockKey,
  LockSimpleOpen,
  PencilSimple,
} from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import moment from 'moment';
import { IVersion } from 'types/Builder.types';
import { CTAModal } from 'components/Modals/CTAModal/CTAModal';
import { useState } from 'react';
import Tooltip from 'components/Tooltip/Tooltip';

interface IVersionRowProps {
  version: IVersion;
  withoutLine: boolean;
  onLock: (id: number, isLocked: boolean) => void;
  onActive: (id: number, isActive: boolean) => void;
  onEdit: (id: number) => void;
  onCopy: (id: number, toNewModel: boolean) => void;
}

const VersionRow = ({
  version,
  withoutLine,
  onLock,
  onActive,
  onEdit,
  onCopy,
}: IVersionRowProps) => {
  const { t } = useTranslation();
  const [isCTAModalOpen, setIsCTAModalOpen] = useState<boolean>(false);

  const lockedByAnotherPerson =
    version.locked && !version.locked_by_current_user;

  return (
    <Container withoutLine={withoutLine}>
      <LeftPart>
        <Version>
          <VersionText>{t('Version')}:</VersionText>
          <VersionNumber> {version.number}</VersionNumber>
        </Version>
        <Status>{version.state_id === 2 ? t('Published') : t('Draft')}</Status>
      </LeftPart>

      <MidPart>
        <DateText>{t('Last modified')}:</DateText>
        <Date>{moment(version.updated_at).format('DD.MM.YYYY. / HH.mm')}</Date>
      </MidPart>

      <RightPart>
        <ButtonsWrapper>
          <ActionButton onClick={() => setIsCTAModalOpen(true)}>
            <Icon
              svg={Copy}
              color={COLORS.GREEN}
              size={30}
              onClick={() => null}
            />
            <ActionText>{t('Copy')}</ActionText>
          </ActionButton>
          <ActionButton locked={lockedByAnotherPerson}>
            {version.state_id === 1 && ( // 1 Draft | 2 Published
              <>
                <Icon
                  svg={PencilSimple}
                  color={lockedByAnotherPerson ? COLORS.BORDER : COLORS.GREEN}
                  disabled={lockedByAnotherPerson}
                  size={30}
                  onClick={() => onEdit(version.id)}
                />
                <ActionText
                  locked={lockedByAnotherPerson}
                  onClick={() => !lockedByAnotherPerson && onEdit(version.id)}
                >
                  {t('Edit')}
                </ActionText>
              </>
            )}
          </ActionButton>
        </ButtonsWrapper>
        <Options>
          <StateWrapper>
            <StateText>
              {version.is_active ? t('Active') : t('Inactive')}
            </StateText>
            <Tooltip
              content={
                version.state_id === 1 ? t('Model must be published') : null
              }
            >
              <ToggleSwitch
                isChecked={version.is_active}
                disabled={version.state_id === 1}
                size="large"
                onClick={() => onActive(version.id, !version.is_active)}
              />
            </Tooltip>
          </StateWrapper>
          <IconWrapper>
            {version.state_id === 1 && (
              <Tooltip
                content={
                  lockedByAnotherPerson
                    ? t('This version has been locked by someone else')
                    : null
                }
              >
                <Icon
                  svg={version.locked ? LockKey : LockSimpleOpen}
                  color={COLORS.GREEN}
                  size={30}
                  disabled={lockedByAnotherPerson}
                  onClick={() => onLock(version.id, !version.locked)}
                />
              </Tooltip>
            )}
          </IconWrapper>
        </Options>
      </RightPart>
      <CTAModal
        isOpen={isCTAModalOpen}
        setIsOpen={setIsCTAModalOpen}
        firstButtonClick={() => {
          onCopy(version.id, false);
          setIsCTAModalOpen(false);
        }}
        firstButtonText={t('Current model')}
        secondButtonClick={() => {
          onCopy(version.id, true);
          setIsCTAModalOpen(false);
        }}
        secondButtonText={t('New Model')}
        title={t('Copy version')}
        description={t('Choose where do you want to copy this version')}
      />
    </Container>
  );
};

export default VersionRow;
