import { useGetCategoriesInfinite } from 'pages/ModelsPage/ModelPageGrid/SupplierCategoriesGrid/hooks';
import { CATEGORIES_PER_PAGE } from './constants';
import { IOption } from 'components/Select/type';
import { ICategory } from 'types/Category.types';
import { getDataFromPages } from 'utils/getDataFromPages';

export const useGetCategoryOptions = (debouncedCategoriesSearchBy: string) => {
  const {
    data: categoriesData,
    fetchNextPage,
    hasNextPage,
  } = useGetCategoriesInfinite(
    null,
    CATEGORIES_PER_PAGE,
    debouncedCategoriesSearchBy
  );

  const categories: ICategory[] = getDataFromPages(
    categoriesData?.pages,
    'categories'
  );

  const categoryOptions: IOption[] = categories.map((category: ICategory) => {
    return {
      value: String(category.id),
      label: category.name,
      key: String(category.id),
    };
  });

  if (
    debouncedCategoriesSearchBy === '' ||
    debouncedCategoriesSearchBy.includes('all')
  ) {
    const allCategoriesOption: IOption = { value: '', label: 'All categories' };
    categoryOptions.push(allCategoriesOption);
  }

  return { categoryOptions, fetchNextPage, hasNextPage };
};
