import { useTranslation } from 'react-i18next';
import {
  Container,
  FieldName,
  Row,
  RowName,
  SelectWrapper,
  TextAreaWrapper,
} from './General.styled';
import Input from 'components/Input/Input';
import { Select } from 'components/Select/Select';
import { useGetCategoryOptions } from 'pages/AllModelsPage/hooks';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'utils/hooks/useDebounce';
import { ICustomModel } from 'types/Builder.types';
import { IOption } from 'components/Select/type';
import CustomMenuList from 'components/Select/components/CustomMenuList/CustomMenuList';
import { ManageCategoriesModal } from 'components/Modals/ManageCategoriesModal/ManageCategoriesModal';
import Icon from 'components/Icon/Icon';
import { Asterisk } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';

interface IProps {
  isAccordionOpen: boolean;
  model: ICustomModel;
  setModel: Dispatch<SetStateAction<ICustomModel>>;
}

const General = ({ isAccordionOpen, model, setModel }: IProps) => {
  const { t } = useTranslation();
  const scrollPositionRef = useRef(0);
  const [categoriesSearchBy, setCategoriesSearchBy] = useState<string>('');
  const [isManageCategoriesOpen, setIsManageCategoriesOpen] =
    useState<boolean>(false);
  const debouncedCategoriesSearchBy = useDebounce(categoriesSearchBy);
  const [name, setName] = useState(model.general.name || '');
  const [description, setDescription] = useState(
    model.general.description || ''
  );
  const debouncedName = useDebounce(name || '');
  const debouncedDescription = useDebounce(description);

  useEffect(() => {
    setName(model.general.name || '');
  }, [model.general.name]);

  useEffect(() => {
    setDescription(model.general.description || '');
  }, [model.general.description]);

  useEffect(() => {
    setModel((prevModel) => ({
      ...prevModel,
      general: {
        ...prevModel.general,
        name: debouncedName.trim() === '' ? null : debouncedName,
      },
    }));
  }, [debouncedName, setModel]);

  useEffect(() => {
    setModel((prevModel) => ({
      ...prevModel,
      general: {
        ...prevModel.general,
        description: debouncedDescription,
      },
    }));
  }, [debouncedDescription, setModel]);

  const { categoryOptions, fetchNextPage, hasNextPage } = useGetCategoryOptions(
    debouncedCategoriesSearchBy
  );

  useEffect(() => {
    if (model.general.category === '') {
      setModel((prevModel) => ({
        ...prevModel,
        general: {
          ...prevModel.general,
          category: null,
        },
      }));
    }
  }, [model.general.category, setModel]);

  return (
    <>
      {isAccordionOpen && (
        <Container>
          <Row>
            <RowName>
              <FieldName>{t('Name')}</FieldName>
              <Icon svg={Asterisk} size={10} color={COLORS.RED_100} />
            </RowName>
            <Input
              placeholder={t('Product name')}
              width={'260rem'}
              value={name ?? ''}
              onChange={(e) => setName(e.target.value)}
            />
          </Row>
          <Row>
            <FieldName>{t('Description')}</FieldName>
            <TextAreaWrapper>
              <Input
                placeholder={t('Description')}
                isTextArea
                wrapperStyles={{ width: '100%' }}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </TextAreaWrapper>
          </Row>
          <Row>
            <FieldName>{t('Category')}</FieldName>
            <SelectWrapper>
              <Select
                key={`${model.general.category}`}
                width="100%"
                controlledValue={categoryOptions?.find(
                  (option) => option.value === model.general.category
                )}
                name="category-select"
                placeholder={t('Category')}
                menuIsOpen={isManageCategoriesOpen === true ? false : undefined}
                options={categoryOptions}
                onChange={(selectedOption: IOption) => {
                  setModel((prevModel) => ({
                    ...prevModel,
                    general: {
                      ...prevModel.general,
                      category: selectedOption.value ?? null,
                    },
                  }));
                }}
                onInputChange={(value: string) => setCategoriesSearchBy(value)}
                components={{
                  MenuList: (props) => (
                    <CustomMenuList
                      {...props}
                      scrollPositionRef={scrollPositionRef}
                      label={t('Manage product categories')}
                      onClick={() => {
                        setIsManageCategoriesOpen(true);
                      }}
                      onMenuScrollToBottom={() =>
                        hasNextPage && fetchNextPage()
                      }
                    />
                  ),
                }}
              />
            </SelectWrapper>
          </Row>

          <ManageCategoriesModal
            isOpen={isManageCategoriesOpen}
            setIsOpen={setIsManageCategoriesOpen}
          />
        </Container>
      )}
    </>
  );
};

export default General;
