import { Skeleton } from '@mui/material';
import { COLORS, borderRadiusSm, marginSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import {
  MODEL_CARD_CONTAINER_HEIGHT,
  MODEL_CARD_CONTAINER_WIDTH,
} from 'components/ModelCard/ModelCard.styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  opacityIndex: number;
  opacityCoeficient: number;
  imageLoaded: boolean;
}

export const Container = styled.div`
  cursor: pointer;
  width: ${MODEL_CARD_CONTAINER_WIDTH}rem;
  height: ${MODEL_CARD_CONTAINER_HEIGHT}rem;
  position: relative;
  display: flex;
  flex-direction: column;
  ${(props: StyledProps) =>
    props.opacityIndex !== undefined &&
    css`
      opacity: ${1 - (props.opacityIndex * props.opacityCoeficient + 0.2)};
    `}
  transition: transform ease-in-out 50ms;
  &:hover {
    transform: translateY(-2rem);
  }

  ${respondTo.smallLaptop`
    max-width: 300rem;
    width: 100%;
  `}

  ${respondTo.bigPhone`
    max-width: 100%;
    height: auto;
    margin-bottom: 20rem;
  `}
`;

export const imageStyle = css`
  width: 100%;
  ${(props: StyledProps) =>
    props.imageLoaded
      ? css`
          width: 100%;
          object-fit: contain;
          border-radius: ${borderRadiusSm};
          cursor: pointer;
          border: 1px solid ${COLORS.BORDER};
          ${respondTo.smallLaptop`
            min-height: 200rem;
          `}
        `
      : css`
          width: 0;
          height: 0;
          opacity: 0;
        `};
`;

export const NameLabel = styled.label`
  font-weight: 400;
  font-size: 24rem;
  color: ${COLORS.BLACK};
  cursor: pointer;
`;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10rem;
  margin-top: ${marginSm};
`;

export const AbsoluteCircleWrapper = styled.label`
  position: absolute;
  display: flex;
  right: 0rem;
  top: 0rem;
  background-color: ${COLORS.GREEN_DISABLED};
  border-bottom-left-radius: ${borderRadiusSm};
  border-top-right-radius: ${borderRadiusSm};
  padding-left: 5rem;
  padding-right: 5rem;
`;

export const SupplierIdLabel = styled.label`
  margin-left: 3rem;
  margin-right: 3rem;
  color: ${COLORS.STRICT_BLACK};
`;

export const StyledSkeleton = styled(Skeleton)`
  border-radius: ${borderRadiusSm};
`;
