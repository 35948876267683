import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { Outlet } from 'react-router';
import { useShowLoadingScreen } from './hooks';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';

const LoadingScreenProvider = () => {
  const showLoadingScreen = useShowLoadingScreen();
  const { darkMode } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );
  return (
    <>
      {showLoadingScreen ? <LoadingScreen isDarkMode={darkMode} /> : null}
      <Outlet />
    </>
  );
};

export default LoadingScreenProvider;
