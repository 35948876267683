import { CSSProperties } from 'react';
import {
  CheckboxInput,
  CheckboxSpan,
  ClickableContainer,
  Container,
} from './Checkbox.styled';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';

interface ICheckboxProps {
  label: string;
  isChecked: boolean;
  onChange: (param: boolean) => void;
  style?: CSSProperties;
}

export const Checkbox = ({
  label,
  onChange,
  isChecked,
  style,
}: ICheckboxProps) => {
  const { darkMode } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );
  return (
    <Container>
      <ClickableContainer onClick={() => onChange(!isChecked)}>
        <CheckboxInput
          darkMode={darkMode}
          onChange={() => {
            onChange(!isChecked);
          }}
          checked={isChecked}
        />
        <CheckboxSpan style={style}>{label}</CheckboxSpan>
      </ClickableContainer>
    </Container>
  );
};
