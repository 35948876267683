import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { getCompanySupplierPermissionsAndIntent } from 'services/IntentService';
import { LogyxError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';

export const useGetCompanySupplierPermissionsAndIntent = () =>
  useMutation(() => getCompanySupplierPermissionsAndIntent(), {
    onError: (error: LogyxError) => {
      toast.error(getToastErrorMessage(error));
    },
    mutationKey: ReactMutationKeys.GET_COMPANY_SUPPLIER_PERMISSIONS_AND_INTENT,
  });
