import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import {
  Container,
  Title,
  ButtonWrapper,
  ErrorMessage,
  RelativeWrapper,
} from './SelectVATAmountModal.styled';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { Percent } from '@phosphor-icons/react';
import { Input } from 'components/Input/InputFormik';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { useNavigateWithQuery } from 'utils/hooks/useNavigateWithQuery';
import { RoutesConfig } from 'navigation/routes';
import { Modal } from 'components/Modals/Modal/Modal';
import { selectVATModalValidationSchema } from './validation';
import { setTestCompanyModelConfiguration } from 'store/SubmitConfiguration/actions/submitConfiguration';
import { useDispatch } from 'react-redux';

export interface ITestDealerPanelConfigurationNavigationState {
  supplierHasSystemIntegration: boolean;
  existingConfigurationId: number | null;
}

export interface ISelectVATAmountModalProps {
  modelIdForTest: number | null;
  setModelIdForTest: Dispatch<SetStateAction<number | null>>;
  supplierHasSystemIntegration: boolean;
  existingConfigurationId: number | null;
}

export function SelectVATAmountModal({
  modelIdForTest,
  setModelIdForTest,
  supplierHasSystemIntegration,
  existingConfigurationId,
}: ISelectVATAmountModalProps) {
  const dispatch = useDispatch();
  const navigateWithQuery = useNavigateWithQuery();
  const { t } = useTranslation();
  const formikRef = useRef<FormikProps<any> | any>();
  const containerRef = useRef<HTMLDivElement>(null);

  const { isPhone } = useBreakpointFlag();
  const handleSubmit = () => {
    if (modelIdForTest && formikRef.current?.isValid) {
      const queryParamsObj = {
        modelId: modelIdForTest.toString(),
        taxRate: formikRef.current?.values?.tax_rate,
      };
      const state: ITestDealerPanelConfigurationNavigationState = {
        supplierHasSystemIntegration: supplierHasSystemIntegration,
        existingConfigurationId: existingConfigurationId,
      };
      navigateWithQuery(
        RoutesConfig.Configure.fullPath,
        { state: state },
        queryParamsObj,
        ['editVersionId']
      );
    }
  };

  // Focus modal when opened
  useEffect(() => {
    if (modelIdForTest) {
      const firstInput =
        containerRef.current?.querySelector<HTMLInputElement>('input');
      if (firstInput) {
        firstInput.focus();
      }
    }
  }, [modelIdForTest]);

  // Submit on enter
  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === 'Enter' && !!modelIdForTest) {
        handleSubmit();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [modelIdForTest]);

  const handleClose = () => {
    setModelIdForTest(null);
    dispatch(setTestCompanyModelConfiguration(null));
  };

  return (
    <Modal
      isOpen={!!modelIdForTest}
      onClose={() => handleClose()}
      modalStyle={{
        margin: 'auto',
        width: isPhone ? '90%' : '600rem',
        minWidth: isPhone ? '90%' : 'auto',
        position: 'fixed',
      }}
    >
      <Container ref={containerRef}>
        <Title>{t('VAT percentage for testing')}</Title>
        <Formik
          innerRef={formikRef}
          initialValues={{
            tax_rate: 0,
          }}
          validationSchema={selectVATModalValidationSchema}
          validateOnChange={true}
          validateOnMount={true}
          validateOnBlur={true}
          onSubmit={() => {
            //
          }}
        >
          {({ errors, touched, setFieldTouched }) => {
            return (
              <Form>
                <RelativeWrapper>
                  <Input
                    name="tax_rate"
                    placeholder={t('VAT')}
                    height={'40rem'}
                    wrapperStyles={{ width: '250rem' }}
                    onFocus={() => {
                      setFieldTouched('tax_rate', true);
                    }}
                    icon={Percent}
                  />
                  <ErrorMessage>
                    {touched['tax_rate'] ? errors['tax_rate'] : null}
                  </ErrorMessage>
                </RelativeWrapper>
              </Form>
            );
          }}
        </Formik>
        <ButtonWrapper>
          <Button
            secondary
            label={t('Cancel')}
            width={isPhone ? '100rem' : '200rem'}
            onClick={() => handleClose()}
          />
          <Button
            primary
            label={t('Confirm')}
            width={isPhone ? '100rem' : '200rem'}
            onClick={() => handleSubmit()}
          />
        </ButtonWrapper>
      </Container>
    </Modal>
  );
}
