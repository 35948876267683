import { useTranslation } from 'react-i18next';
import {
  FieldTypes,
  ICustomModel,
  IInputField,
} from '../../../types/Builder.types';
import {
  Container,
  FieldTypeText,
  FormContainer,
  Headline,
  TopWrapper,
} from './FieldConfiguration.styled';
import ShortTextForm from './ConfigForms/ShortTextForm/ShortTextForm';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import LongTextForm from './ConfigForms/LongTextForm/LongTextForm';
import NumberForm from './ConfigForms/NumberForm/NumberForm';
import RadioForm from './ConfigForms/RadioForm/RadioForm';
import ListForm from './ConfigForms/ListForm/ListForm';
import ToggleForm from './ConfigForms/ToggleForm/ToggleForm';
import Icon from 'components/Icon/Icon';
import { X } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';

interface IProps {
  currentSelectedField: IInputField;
  setModel: Dispatch<SetStateAction<ICustomModel>>;
  model: ICustomModel;
  setSelectedFieldId: Dispatch<SetStateAction<string>>;
}

const FieldConfiguration = ({
  currentSelectedField,
  setModel,
  model,
  setSelectedFieldId,
}: IProps) => {
  const { t } = useTranslation();
  const [animationKey, setAnimationKey] = useState(0);

  useEffect(() => {
    if (currentSelectedField?.field_type) {
      setAnimationKey((prev) => prev + 1);
    }
  }, [currentSelectedField?.field_type]);

  const updateModel = (values: any) => {
    setModel((prevModel) => ({
      ...prevModel,
      input_fields: prevModel.input_fields.map((field) => {
        if (field.id === currentSelectedField.id) {
          switch (field.field_type) {
            case FieldTypes.ShortText:
              return {
                ...field,
                field_label: values.field_label,
                description: values.description,
                default: values.default,
                min: values.min,
                max: values.max,
                required: values.required,
              };
            case FieldTypes.LongText:
              return {
                ...field,
                field_label: values.field_label,
                description: values.description,
                default: values.default,
                min: values.min,
                max: values.max,
                required: values.required,
              };
            case FieldTypes.Number:
              return {
                ...field,
                field_label: values.field_label,
                description: values.description,
                default: values.default,
                min: values.min,
                max: values.max,
                required: values.required,
              };
            case FieldTypes.Radio:
              return {
                ...field,
                field_label: values.field_label,
                description: values.description,
                default: values.default,
                options: values.options,
              };
            case FieldTypes.List:
              return {
                ...field,
                field_label: values.field_label,
                description: values.description,
                default: values.default,
                options: values.options,
                required: values.required,
                multiple: values.multiple,
              };
            case FieldTypes.Toggle:
              return {
                ...field,
                field_label: values.field_label,
                description: values.description,
                default: values.default,
                price: values.price,
              };
          }
        }
        return field;
      }),
    }));
  };

  return (
    <Container>
      {currentSelectedField && (
        <>
          <TopWrapper>
            <Headline>{t('Field Configuration')}</Headline>
            <Icon
              svg={X}
              color={COLORS.GREEN}
              weight="regular"
              size={26}
              onClick={() => setSelectedFieldId('')}
            />
          </TopWrapper>
          <FieldTypeText>
            {currentSelectedField?.default_field_name}
          </FieldTypeText>
          <div className={`animation-${animationKey % 2}`}>
            <FormContainer animationKey={animationKey}>
              {(() => {
                switch (currentSelectedField.field_type) {
                  case FieldTypes.ShortText:
                    return (
                      <ShortTextForm
                        currentSelectedField={currentSelectedField}
                        updateModel={updateModel}
                      />
                    );
                  case FieldTypes.LongText:
                    return (
                      <LongTextForm
                        currentSelectedField={currentSelectedField}
                        updateModel={updateModel}
                      />
                    );
                  case FieldTypes.List:
                    return (
                      <ListForm
                        currentSelectedField={currentSelectedField}
                        updateModel={updateModel}
                        model={model}
                      />
                    );
                  case FieldTypes.Number:
                    return (
                      <NumberForm
                        currentSelectedField={currentSelectedField}
                        updateModel={updateModel}
                        model={model}
                      />
                    );
                  case FieldTypes.Toggle:
                    return (
                      <ToggleForm
                        currentSelectedField={currentSelectedField}
                        updateModel={updateModel}
                      />
                    );
                  case FieldTypes.Radio:
                    return (
                      <RadioForm
                        currentSelectedField={currentSelectedField}
                        updateModel={updateModel}
                      />
                    );
                  default:
                    return null;
                }
              })()}
            </FormContainer>
          </div>
        </>
      )}
    </Container>
  );
};

export default FieldConfiguration;
